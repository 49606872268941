.collection-card {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
}

.collection-card .like-font {
  color: #dc3545;
}

.collection-card .like-icon {
  width: 14px;
  height: 14px;
}

.collection-card .user-image {
  width: min(100%, 24px);
  height: min(100%, 24px);
}

.collection-card .username {
  color: #1890ff;
  font-weight: 500;
}

.collection-card .moreInfoBtn {
  background-color: #585a96;
}

.collection-card .addToCollection {
  border: 1px solid #585a96;
  color: #585a96;
}
.collection-card .collection-img {
  width: 100%;
  max-height: 150px;
  min-height: 150px;
}
.footer {
  margin-top: auto;
}
