.mw-400{
    max-width: 400px;
}

.mw-450{
    max-width: 450px;
}

.mw-200{
    max-width: 200px !important;
}