@import url(https://fonts.googleapis.com/css2?family=Karla:wght@300;400;600;800&family=Open+Sans:wght@400;600&display=swap);
body {
  margin: 0;
  font-family: "Karla",-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:15px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dropdown-toggle::after{
  vertical-align: .115em;
  border-right: .25em solid transparent;
}

.fw-6{
  font-weight: 600 !important;
}

.navbar-toggler{
  border: none;
}

.navbar-toggler:focus{
  outline: none;
}
 *{
  font-family: 'Karla', sans-serif !important;
 }
.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.mt-58{
  margin-top: 65px;
}

.c-ptr{
  cursor: pointer;
}

.fw-500{
  font-weight: 500;
}

.fw-600{
  font-weight: 600;
}

.cursor-pointer{
  cursor: pointer;
}
.dash.navbar{
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
}

.bg-light{
    background-color: #fafafa;
}

.shadow{
    box-shadow: 0 4px 10px 0 rgb(200 200 200 / 50%);
}

.nav-link{
    align-items: center;
    display: flex;
}

.nav-link.active{
    /* color: #007bff !important; */
}

.logoutBtn{
    color:#dc3545 !important;
}
.logoutBtn:hover{
    color: white !important;
}
.longLine{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
}

.mw-100{
    max-width: 100px !important;
}

.mw-200{
    max-width: 200px !important;
}

.pt-cur{
    cursor: pointer;
}
.mw-400{
    max-width: 400px;
}

.mw-450{
    max-width: 450px;
}

.mw-200{
    max-width: 200px !important;
}
.check {
  text-align: center;
  font-weight: 600;
  color: white;
  border-radius: 10px !important;
  outline: none;
}

.check:checked {
  background-color: #0e9700 !important;
  border-color: #0e9700 !important;
}

.topic {
  font-weight: 400;
  text-align: start;
}

.subtitle {
  text-align: start;
}

.digit {
  margin-right: 18px;
  padding-right: 10px;
  padding-left: 10px;
  cursor: pointer;
}

.digit:hover {
  outline: 1px solid black;
}

.digit-label {
  padding-right: 10px;
  padding-left: 10px;
  cursor: pointer;
}

.digit-label:hover {
  outline: 1px solid black;
}

.input {
  width: 58px;
}

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100px;
  margin: auto;
}

.students {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
  /* width: 223%; */
}

.student-div{
  display: flex;
  padding-left: 10px !important;
}
.bg-acv{
    background: linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) ,url(/static/media/illustration.f4db2004.jpg) no-repeat center center fixed;
    background-size: cover;
    -webkit-backdrop-filter: 20%;
            backdrop-filter: 20%;
}

.h-100vh{
    height: 100vh;
}
.collection-container{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 15px;
    gap: 15px;
}
.collection-card {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
}

.collection-card .like-font {
  color: #dc3545;
}

.collection-card .like-icon {
  width: 14px;
  height: 14px;
}

.collection-card .user-image {
  width: min(100%, 24px);
  height: min(100%, 24px);
}

.collection-card .username {
  color: #1890ff;
  font-weight: 500;
}

.collection-card .moreInfoBtn {
  background-color: #585a96;
}

.collection-card .addToCollection {
  border: 1px solid #585a96;
  color: #585a96;
}
.collection-card .collection-img {
  width: 100%;
  max-height: 150px;
  min-height: 150px;
}
.footer {
  margin-top: auto;
}

