.check {
  text-align: center;
  font-weight: 600;
  color: white;
  border-radius: 10px !important;
  outline: none;
}

.check:checked {
  background-color: #0e9700 !important;
  border-color: #0e9700 !important;
}

.topic {
  font-weight: 400;
  text-align: start;
}

.subtitle {
  text-align: start;
}

.digit {
  margin-right: 18px;
  padding-right: 10px;
  padding-left: 10px;
  cursor: pointer;
}

.digit:hover {
  outline: 1px solid black;
}

.digit-label {
  padding-right: 10px;
  padding-left: 10px;
  cursor: pointer;
}

.digit-label:hover {
  outline: 1px solid black;
}

.input {
  width: 58px;
}

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100px;
  margin: auto;
}

.students {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
  /* width: 223%; */
}

.student-div{
  display: flex;
  padding-left: 10px !important;
}