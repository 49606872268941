.bg-acv{
    background: linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) ,url("../../../Assets/Images/illustration.jpg") no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    backdrop-filter: 20%;
}

.h-100vh{
    height: 100vh;
}