.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.mt-58{
  margin-top: 65px;
}

.c-ptr{
  cursor: pointer;
}

.fw-500{
  font-weight: 500;
}

.fw-600{
  font-weight: 600;
}

.cursor-pointer{
  cursor: pointer;
}